<template>
    <div class="discipline">
        <dn-loader v-if="showLoader" class="with-margins"/>
        <div v-else>
            <b-row class="dn-gray-color pb-1 border-bottom">
                <b-col class="col-12 col-lg-6 field-name">{{ fieldName }}</b-col>
                <b-col class="col-12 col-lg-6 manager-name" v-if="hasRight('view_discipline_manager')">
                    <span style="margin-right: .5rem; text-transform: uppercase;">{{ $tc('views.discipline.show.manager', managers.length) }}:</span>
                    <span v-if="discipline.managers.length === 0" class="no-manager">{{ $t('components.dn_field.no_manager') }}</span>
                    <span v-else v-for="manager in discipline.managers" :key="manager.id" class="one-manager">
                        <template v-if="manager.employeeId">
                            <router-link :to="{ name: 'employee_path', params: { id: manager.employeeId } }" >
                                {{ manager.managerName }}
                            </router-link>
                        </template>
                        <template v-else>
                            {{ manager.managerName }}
                        </template>
                    </span>
                    <span v-if="hasRight('edit_discipline_manager')" class="change" @click="managersEdit(discipline)">{{ $t('components.dn_field.choose') }}</span>
                    <b-modal ref="managers"
                             :hide-header="true"
                             v-bind:ok-title="$t('general.confirm')"
                             ok-variant="primary"
                             :centered="true"
                             :no-close-on-esc="true"
                             @ok="managersSave"
                             v-bind:cancel-title="$t('general.cancel')"
                             cancel-variant="default"
                             @hide="cleanManagers"
                             footer-class="my-modal-footer"
                             content-class="my-modal-content"
                    >
                        <dn-discipline-managers v-model="managers" :errors="errors"></dn-discipline-managers>
                    </b-modal>
                </b-col>
            </b-row>
            <b-row>
                <b-col :class="{ 'col-12': (!hasRight('simulate_employments')), 'col-8': (hasRight('simulate_employments')) }">
                    <span v-if="hasRight('evaluation_algorithm')" class="evaluation">{{ $t('views.discipline.show.evaluation') }}:</span>
                    <pcg-btn v-if="((discipline.publicationsStatements > 0 || discipline.numberN > 0) && hasRight('evaluation_algorithm'))"
                             class="my-btn" size="small" @click="updateEvaluation" :disabled="(importSpinner || simulateSpinner)">
                        {{ $t('views.discipline.show.run') }}
                        <spinner v-if="importSpinner" />
                    </pcg-btn>
                    <span v-else-if="hasRight('evaluation_algorithm')" :id="'discipline-' + discipline.id">
                        <pcg-btn class="my-btn" size="small" @click="updateEvaluation" :disabled="discipline.publicationsStatements === 0">
                            {{ $t('views.discipline.show.run') }}
                            <spinner v-if="importSpinner" />
                        </pcg-btn>
                    </span>
                    <b-tooltip :target="'discipline-' + discipline.id">
                        {{ $t('views.discipline.show.no_publications') }}
                    </b-tooltip>
                    <div style="display: inline-block">
                        <span class="evaluation">{{ $t('views.discipline.show.last_launch') }}:</span>
                        <span v-if="discipline.evaluationDate" class="evaluation" style="margin-right: .8rem">{{ $d(Date.parse(discipline.evaluationDate)) }} {{ setTime(discipline) }}</span>
                        <span v-else class="evaluation" style="margin-right: .8rem"></span>
                    </div>
                    <pcg-checkbox style="display: inline-block"
                                  v-if="discipline.publicationsStatements > 0 && hasRight('evaluation_minuses')"
                                  variant="normal"
                                  v-model="includeMinuses"
                                  @input="changeIgnoreMinuses(discipline)"
                                  :disabled="(importSpinner || simulateSpinner)"
                    >
                        {{ $t('views.discipline.show.ignore_minuses') }}
                    </pcg-checkbox>
                </b-col>
                <b-col v-if="hasRight('simulate_employments')" class="col-4 mt-3">
                  <div class="d-flex align-items-center justify-content-end">
                    <spinner class="simulate-spinner" v-if="simulateSpinner" />
                    <pcg-switch v-model="simulateEmployments"
                                @input="changeSimulateEmployments()"
                                :disabled="(importSpinner || simulateSpinner)"
                                class="ml-4 mr-2" style="width: 32px"
                    />
                    <span class="ml-2" style="position: relative; top: .1rem;"
                          :class="{ 'pcg-main-active-color': simulateEmployments,
                                'pcg-gray-color': !simulateEmployments }"
                    >
                      {{ $t('views.discipline.show.simulate_employment') }}
                    </span>
                  </div>
                </b-col>
            </b-row>
            <b-row v-if="hasRight('number_n_algorithm')">
                <b-col>
                    <span class="evaluation">{{ $t('views.discipline.show.number_n') }}:</span>
                    <span :id="'numbern-' + discipline.id">
                        <pcg-btn class="my-btn" size="small" @click="updateNumberN" :disabled="(numberNSpinner || importSpinner || simulateSpinner)">
                            {{ $t('views.discipline.show.run') }}
                            <spinner v-if="numberNSpinner" />
                        </pcg-btn>
                    </span>

                </b-col>
            </b-row>
            <b-row>
                <b-col class="discipline-name">
                    {{ $i18n.locale === 'pl' ? discipline.name : discipline.nameEn }}
                </b-col>
            </b-row>
            <div class="mt-3 row">
                <dn-box class="my-box">
                    <h2 class="mb-3 pcg-main-color">{{ $t('views.discipline.show.result') }}</h2>
                    <div class="actual-score">
                        <div style="margin-bottom: .7rem">
                            <img v-if="myContrast" src="../../assets/images/uczelnia-negatyw.svg" class="my-score-icon" :alt="$t('general.academy')"/>
                            <img v-else src="../../assets/images/uczelnia.svg" class="my-score-icon" :alt="$t('general.academy')"/>
                            <div class="numbers-light">{{ disciplinePoints(discipline) }}</div>
                            <span class="discipline-points">{{ $tc('views.discipline.show.points', disciplinePoints(discipline)) }}</span>
                        </div>
                        <div style="margin-bottom: .7rem">
                            <div v-if="discipline.numberN > 0">
                                <span class="my-n-icon">N</span>
                                <div class="numbers-light">{{ numberNPoints(discipline) }}</div>
                                <span class="discipline-points">{{ $tc('views.discipline.show.points', numberNPoints(discipline)) }}</span>
                                <span class="discipline-points">{{ $t('views.discipline.show.per_n') }}</span>
                            </div>
                        </div>
                        <div style="margin-bottom: .7rem">
                            <dn-pie-chart :filling="filledSlots" color="dark-blue" class="slots-chart"></dn-pie-chart>
                            <div class="numbers-light">
                                <span>{{ employeeSlots }}</span>
                                <span>{{ $t('components.dn_evaluation.with') }}</span>
                                <span>{{ allSlots }}</span>
                            </div>
                            <span v-if="discipline.short === 'DS010801N' || discipline.short === 'DS010802N' || discipline.short === 'DS010803N'" class="discipline-points">
                                {{ $t('views.discipline.show.limits_filling') }}
                            </span>
                            <span v-else class="discipline-points">{{ $t('views.discipline.show.slots_filling') }}</span>
                            <span class="discipline-points">({{ $n(filledSlots) }}%)</span>
                        </div>
                        <div v-if="discipline.limit220 !== null && discipline.limit220 !== undefined && years[years.length - 1] === 2021">
                          <div>
                            <dn-pie-chart :filling="filledSlots" color="dark-blue" class="slots-chart"></dn-pie-chart>
                            <span class="numbers-light">{{ $t('views.discipline.show.limit220_num', {num: discipline.limit220.toFixed(2), out_of: limit220(discipline)} )}}</span>
                            <span class="discipline-points"> {{ $t('views.discipline.show.limit220_desc') }}</span>
                          </div>
                        </div>
                    </div>
                    <b-row class="mt-3 oneline">
                        <b-col>
                            <h2 class="pcg-main-color oneline">{{ $t('views.discipline.show.estimated')}}</h2>
                            <p v-if="discipline.short === 'DS010801N' || discipline.short === 'DS010802N' || discipline.short === 'DS010803N'" class="dn-gray-color">{{ $t('views.discipline.show.estimated_text_artistic')}}</p>
                            <p v-else class="dn-gray-color">{{ $t('views.discipline.show.estimated_text')}}</p>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col>
                            <span class="numbers-dashed">{{ estimatedPoints }}</span>
                            <span class="discipline-points">{{ $tc('views.discipline.show.points', estimatedPoints) }}</span>
                        </b-col>
                    </b-row>
                </dn-box>
                <dn-box class="my-box">
                    <h2 class="mb-3 pcg-main-color">{{ $t('views.discipline.show.statements') }}</h2>
                    <h3 class="mb-2 dn-dark-gray-color">{{ $t('views.discipline.show.choice') }}</h3>
                    <div class="mb-1 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.persons') }}</div>
                        <div class="blue-number">{{ discipline.persons }}</div>
                    </div>
                    <div class="mb-3 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.persons_one') }}</div>
                        <div class="blue-number">{{ discipline.personsOne }}</div>
                    </div>
                    <h3 class="mb-2 dn-dark-gray-color">{{ $t('views.discipline.show.number_n') }}</h3>
                    <div class="mb-1 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.number_n_persons') }}</div>
                        <div class="blue-number">{{ discipline.numberNChangedCounter }}</div>
                    </div>
                    <div class="mb-1 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.number_n_average', {from: years[0], to: years[years.length - 1]}) }}</div>
                      <div class="blue-number">{{ (discipline.numberN ? discipline.numberN.toFixed(2) : 0) }}</div>
                    </div>
                    <div class="conversion-jobs">
                      <dn-progress-bar :progressPercent="conversionJobsPercentage"/>
                    </div>
                    <div class="mb-1 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.number_n_last_year', {year: years[years.length - 1]}) }}</div>
                      <div>
                        <span class="blue-number">{{ discipline.numberNLastYear }}</span>
                        <span v-if="missingJobs > 0" class="text-right small" style="margin-top: .5rem; margin-left: .3rem;">
                              <span v-b-tooltip.hover v-bind:title="$t('components.dn_field.not_enough_job_positions_tooltip')">
                                  <img src="../../assets/images/exclamation-mark-red.svg" class="icon-not-enough"/>
                              </span>
                                  <span class="dn-gray-color">{{ $tc('views.discipline.show.missing_jobs', missingJobs) }}</span>
                              </span>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between" :style="[ discipline.ignoreMinuses ? { 'margin-bottom': '1rem' } : { 'margin-bottom': '0' } ]">
                        <div class="persons">{{ $t('views.discipline.show.3n') }}</div>
                        <div class="blue-number">{{ discipline.number3nMinuses? discipline.number3nMinuses.toFixed(2) : 0 }}</div>
                    </div>
                    <div v-if="!discipline.ignoreMinuses" class="mb-3 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.3n_minuses') }}</div>
                        <div class="blue-number">{{ discipline.numberNMinuses }}</div>
                    </div>
                    <h3 class="mb-2 dn-dark-gray-color">{{ $t('views.discipline.show.reported_achievements') }}</h3>
                    <div class="mb-1 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.publications_assigned_statement') }}</div>
                      <div class="blue-number">{{ discipline.publicationsStatements }}</div>
                    </div>
                    <div class="mb-1 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.publications_accepted_to_eval') }}</div>
                      <div class="blue-number">{{ discipline.publicationsAccepted }}</div>
                    </div>
                    <hr/>
                    <h3 class="mb-2 dn-dark-gray-color">{{ $t('views.discipline.show.first_criterium') }}</h3>
                    <div class="mb-1 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.second_criterium_count') }}</div>
                        <div class="blue-number">{{ discipline.firstCriteriumCount }}</div>
                    </div>
                    <div class="mb-1 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.second_criterium_points') }}</div>
                        <div class="blue-number">{{ discipline.firstCriteriumPoints.toFixed(2) }}</div>
                    </div>
                    <div class="mb-3 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.first_criterium_points_per_n') }}</div>
                      <div class="blue-number">{{ numberNFirstCriteriumPoints(discipline) }}</div>
                    </div>
                    <h3 v-if="discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47" class="mb-2 dn-dark-gray-color">
                      {{ $t('views.discipline.show.second_criterium') }}
                    </h3>
                    <div v-if="discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47" class="mb-1 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.second_criterium_count') }}</div>
                        <div class="blue-number">{{ discipline.secondCriteriumCount }}</div>
                    </div>
                    <div v-if="discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47" class="mb-3 d-flex justify-content-between">
                        <div class="persons">{{ $t('views.discipline.show.second_criterium_points') }}</div>
                        <div class="blue-number">{{ discipline.secondCriteriumPoints.toFixed(2) }}</div>
                    </div>
                    <h3 v-if="discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47" class="mb-2 dn-dark-gray-color">
                      {{ $t('views.discipline.show.third_criterium') }}
                    </h3>
                    <div v-if="discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47" class="mb-1 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.third_criterium_count') }}</div>
                      <div class="blue-number">{{ discipline.thirdCriteriumCount }}</div>
                    </div>
                    <div v-if="discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47" class="mb-1 d-flex justify-content-between">
                      <div class="persons">{{ $t('views.discipline.show.third_criterium_points') }}</div>
                      <div class="blue-number">{{ discipline.thirdCriteriumPoints.toFixed(2) }}</div>
                    </div>
                </dn-box>
            </div>
            <b-row style="margin-top: 2rem" align-h="between">
                <h2 class="dn-header pcg-main-color">{{ $t('views.discipline.show.scientists_list') }}</h2>
                <div style="margin-bottom: 1rem">
                  <span class="sort">{{ $t('views.discipline.show.sort') }}</span>
                  <pcg-sort-btn
                    :options="sortOptions"
                    v-model="sortBy"
                    @input="sortEmployee"
                    class="my-sort"
                  />
                </div>
                <div v-if="hasRight('filter_discipline_minuses')" style="text-align: right">
                  <span class="sort">{{ $t('views.discipline.show.minuses') }}</span>
                  <pcg-select
                      class="my-select-short"
                      :options="tDictionary(yesNo)"
                      :default-option="{ text: $t('general.select'), id: '' }"
                      v-model="filters.minuses"
                      @input="getEmployees"
                      :class="{ 'disabled': (importSpinner || simulateSpinner) }"
                  />
                </div>
                <div v-else style="width: 291px; margin-bottom: 1rem; height: 2rem">&nbsp;</div>
            </b-row>
            <b-row class="slots-row">
                <dn-loader v-if="employeesSpinner || badEmployeesSpinner" class="my-loader"></dn-loader>
                <dn-box v-else-if="!employeesSpinner && employees.length > 0 && !badEmployeesSpinner" class="mr-0">
                    <div class="grid">
                        <div class="slot-1"></div>
                        <div class="slot-2"></div>
                        <div class="slot-3"></div>
                        <div class="slot-4"></div>
                    </div>
                    <scientist-slots
                      v-for="employee in employees"
                      :key="employee.id"
                      :scientist="employee"
                      :short="discipline.short"
                      :max-points="discipline.maxPoints"
                    />
                    <scientist-slots
                      v-for="emp in badEmployees"
                      :key="`badEmp-${emp.id}`"
                      :scientist="emp"
                      :short="discipline.short"
                      :max-points="discipline.maxPoints"
                    />
<!--                    <div class="bad-emp-wrapper" v-if="hasRight('evaluation_minuses')">-->
<!--                      <div class="scientists-slots" v-for="emp in badEmployees" :key="emp.id">-->
<!--                        <div class="scientist">-->
<!--                          <b-col class="col-2">-->
<!--                            <img src="../../assets/images/exclamation-mark.svg" class="my-exclamation-mark-orange" :id="'badEmployee-' + emp.id" :alt="$t('views.periodicals.wrong_discipline')"/>-->
<!--                            <b-tooltip :target="'badEmployee-' + emp.id">-->
<!--                              {{ $t('views.discipline.show.minuses_3') }}-->
<!--                            </b-tooltip>-->
<!--                          </b-col>-->
<!--                          <b-col class="col-4 col-lg-3">-->
<!--                            <router-link :to="{name: 'employee_path', params: {id: emp.id}}">{{ emp.fullName}}</router-link>-->
<!--                          </b-col>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
                </dn-box>
                <dn-box v-else-if="!employeesSpinner && employees.length === 0" class="mr-0">
                    <div class="d-flex justify-content-center no-records">{{ $t('views.discipline.show.no_records') }}</div>
                </dn-box>
            </b-row>
            <b-row style="margin-top: 2rem" align-h="between" v-if="discipline.patentsPublications.length > 0 && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
              <h2 class="dn-header pcg-main-color">{{ $t('views.discipline.show.patents') }}</h2>
            </b-row>
            <div class="achievement-elements" v-if="discipline.patentsPublications.length > 0 && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
              <b-row v-for="publication in discipline.patentsPublications" :key="publication.id" class="achievement mb-3">
                <b-col cols="1" class="scientist-points">
                  <span class="points" :class="pointsClass(publication.publicationPoints)">{{ publicationFixedPoints(publication.publicationPoints) }}</span>
                </b-col>
                <b-col cols="3" class="project-type">{{ publicationType(publication.publicationType) }}</b-col>
                <b-col cols="8" class="project-title">{{ publication.title }}</b-col>
              </b-row>
            </div>
            <b-row style="margin-top: 2rem" align-h="between" v-if="(discipline.projects.length > 0 || discipline.services.length > 0) && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
                <h2 class="dn-header pcg-main-color">{{ $t('views.discipline.show.second_criterium_list') }}</h2>
                <div style="margin-bottom: 1rem">
                  <span class="sort">{{ $t('views.discipline.show.sort') }}</span>
                  <pcg-sort-btn
                      :options="secondCriteriumSortOptions"
                      v-model="secondCriteriumSortBy"
                      @input="sortSecondCriterium"
                      class="my-sort"
                  />
                </div>
            </b-row>
            <div class="achievement-elements" v-if="discipline.projects.length > 0 && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
                <b-row v-for="publication in discipline.projects" :key="publication.id" class="achievement mb-3">
                    <b-col cols="1" class="scientist-points">
                        <span class="points" :class="pointsClass(publication.publicationPoints)">{{ publicationFixedPoints(publication.publicationPoints) }}</span>
                    </b-col>
                    <b-col cols="3" class="project-type">{{ publicationType(publication.publicationType) }}</b-col>
                    <b-col cols="8" class="project-title">{{ publication.title }}</b-col>
                </b-row>
            </div>
            <div class="achievement-elements" v-if="discipline.services.length > 0 && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
              <b-row v-for="publication in discipline.services" :key="publication.id" class="achievement mb-3">
                <b-col cols="1" class="scientist-points">
                  <span class="points" :class="pointsClass(publication.publicationPoints)">{{ publicationFixedPoints(publication.publicationPoints) }}</span>
                </b-col>
                <b-col cols="3" class="project-type">{{ publicationType(publication.publicationType) }}</b-col>
                <b-col cols="8" class="project-title">{{ publication.title }}</b-col>
              </b-row>
            </div>
            <b-row style="margin-top: 2rem" align-h="between" v-if="discipline.thirdCriterium.length > 0 && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
              <div class="dn-header pcg-main-color">{{ $t('views.discipline.show.third_criterium') }}</div>
            </b-row>
            <div class="achievement-elements" v-if="discipline.thirdCriterium.length > 0 && discipline.id !== 45 && discipline.id !== 46 && discipline.id !== 47">
              <b-row v-for="publication in discipline.thirdCriterium" :key="publication.id" class="achievement mb-3">
                <b-col cols="1" class="scientist-points">
                  <span class="points" :class="pointsClass(publication.publicationPoints)">{{ publicationFixedPoints(publication.publicationPoints) }}</span>
                </b-col>
                <b-col cols="3" class="project-type">{{ publicationType(publication.publicationType) }}</b-col>
                <b-col cols="8" class="project-title">{{ publication.title }}</b-col>
              </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import points from '../../mixins/points'
import setTitle from '../../mixins/set_title'
import translateDictionary from '../../mixins/translate_dictionary'
import unexpectedError from '../../mixins/unexpected_error'
import waitForJobs from '../../mixins/wait_for_jobs'
import DnBox from '../../components/DnBox'
import DnProgressBar from '../../components/DnProgressBar'
import ScientistSlots from '../../components/ScientistSlots'
import Spinner from '../../components/Spinner'
import DnPieChart from '../../components/DnPieChart'
import ApiDiscipline from '../../api/disciplins'
import { mapGetters } from 'vuex'
import loaderDelay from '../../mixins/loader_delay'
import DnLoader from '../../components/DnLoader'
import DnDisciplineManagers from '../../components/DnDisciplineManagers'
import hasRight from '../../mixins/has_right'
import pointsClasses from '../../mixins/points_classes'
import ApiAcademy from '../../api/academies'

export default {
  name: 'DisciplineShow',
  mixins: [loaderDelay, hasRight, points, setTitle, translateDictionary, unexpectedError, waitForJobs, pointsClasses],
  components: { DnPieChart, DnBox, DnProgressBar, ScientistSlots, Spinner, DnLoader, DnDisciplineManagers },
  data: function () {
    return {
      headerTitle: 'views.discipline.show.title',
      headerSubtitle: '',
      discipline: { },
      managers: [],
      employees: [],
      badEmployees: [],
      importSpinner: false,
      numberNSpinner: false,
      hField: {},
      roles: [],
      publication_types: [],
      managerSpinner: false,
      lastDiscipline: '',
      employeesSpinner: false,
      badEmployeesSpinner: false,
      filters: {
        minuses: null
      },
      errors: [],
      sortBy: { orderBy: 'points', orderDirection: 'asc' },
      sortOptions: [],
      secondCriteriumSortBy: { orderBy: 'points', orderDirection: 'asc' },
      secondCriteriumSortOptions: [],
      myContrast: false,
      simulateEmployments: false,
      simulateSpinner: false
    }
  },
  beforeMount () {
    if (this.$store.getters['publicationTypes/publicationTypes'].length === 0) {
      this.$store.dispatch('publicationTypes/setPublicationTypes', null)
    }
    if (this.$store.getters['disciplines/disciplines'].length === 0) {
      this.$store.dispatch('disciplines/setDisciplines', null)
    }
    if (this.$store.getters['scienceFields/scienceFields'].length === 0) {
      this.$store.dispatch('scienceFields/setScienceFields', null)
    }
  },
  created: function () {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    // from Api
    this.employeesSpinner = true
    this.badEmployeeSpinner = true
    ApiDiscipline.getDiscipline(this.$route.params.id)
      .then(result => {
        this.discipline = result.data.discipline
        this.simulateEmployments = result.data.discipline.simulateEmployment
        if (this.discipline.short === 'DS010801N' || this.discipline.short === 'DS010802N' || this.discipline.short === 'DS010803N') {
          this.sortOptions[2].text = this.$t('views.discipline.show.sort_by.limits')
        }
        if (this.discipline.jid != null) {
          this.importSpinner = true
          this.waitForJob(this.discipline.jid, this.evaluationUpdate, this.evaluationError)
        }
        this.headerSubtitle = this.$i18n.locale === 'pl' ? this.discipline.name : this.discipline.nameEn
        this.sortSecondCriterium()
        this.setTitles()
      })
      .finally(() => {
        this.loadingDone = true
      })
    ApiDiscipline.getEmployees(this.$route.params.id, this.filters)
      .then(result => {
        this.employees = result.data.employees
        this.addCurrents()
        this.sortEmployee()
        this.employeesSpinner = false
      })
      .catch(error => {
        console.log(error)
        this.employeesSpinner = false
      })
    if (this.hasRight('evaluation_minuses')) {
      ApiDiscipline.getBadEmployees(this.$route.params.id)
        .then(result => {
          this.badEmployees = result.data.employees
          this.badEmployeesSpinner = false
        })
        .catch(error => {
          console.log(error)
          this.badEmployeesSpinner = false
        })
    } else this.badEmployeesSpinner = false
    this.roles = this.$store.getters['auth/roles']
  },
  mounted () {
    this.$store.dispatch('header/setHeader', {
      title: this.$t(this.headerTitle),
      subtitle: this.headerSubtitle,
      capitalizeSubtitle: false
    })
    this.myContrast = this.contrast
    this.buildSortOptions()
  },
  computed: {
    ...mapGetters('scienceFields', ['scienceFields']),
    ...mapGetters('publicationTypes', ['publicationTypes']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('period', ['years']),
    estimatedPoints () {
      if (this.filledSlots > 0) {
        return Math.ceil(((this.discipline.points * 100) / this.filledSlots))
      } else if (this.filledSlots === 100) {
        return this.discipline.points
      } else {
        return 0
      }
    },
    fieldName () {
      const fieldId = this.discipline.scienceFieldId
      if (fieldId === undefined || fieldId.length === 0) {
        return ''
      }
      let name = ''
      const ds = this.tDictionary(this.scienceFields)
      if (ds.length > 0) {
        name = ds.find(d => d.value === fieldId.toString()).text
      } else {
        name = ''
      }
      return name
    },
    filledSlots () {
      let result = 0
      result = Math.round(this.discipline.slotsFilled * 100) / 100.0
      if (result > 100) {
        result = 100
      }
      return result
    },
    employeeSlots () {
      return this.discipline.employeeSlots.toFixed(4) * (10000 / 10000)
    },
    allSlots () {
      return this.discipline.allSlots.toFixed(4) * (10000 / 10000)
    },
    missingJobs () {
      return Math.round((12 - this.discipline.numberNLastYear) * 10000) / 10000
    },
    conversionJobsPercentage () {
      if (this.discipline.numberN === 0) {
        return 0
      } else {
        // return Math.round((this.discipline.conversionJobs * 100) / this.discipline.conversionJobsRequired)
        return (this.discipline.numberN * 100) / 12
      }
    },
    yesNo () {
      return [
        { text: 'Wybierz', text_en: 'Select', id: '' },
        { text: 'Tak', text_en: 'Yes', id: true },
        { text: 'Nie', text_en: 'No', id: false }
      ]
    },
    includeMinuses: {
      get () {
        return !this.discipline.ignoreMinuses
      },
      set (value) {
        this.discipline.ignoreMinuses = !value
      }
    }
  },
  methods: {
    buildSortOptions () {
      this.sortOptions = [
        {
          text: this.$t('views.discipline.show.sort_by.points'),
          value: 'points'
        },
        {
          text: this.$t('views.discipline.show.sort_by.names'),
          value: 'names'
        },
        {
          text: this.$t('views.discipline.show.sort_by.fillings'),
          value: 'fillings'
        }
      ]
      this.secondCriteriumSortOptions = [
        {
          text: this.$t('views.discipline.show.sort_by.points'),
          value: 'points'
        },
        {
          text: this.$t('views.discipline.show.sort_by.titles'),
          value: 'titles'
        }
      ]
    },
    publicationType (id) {
      const idx = this.publicationTypes.findIndex(r => {
        return r.id === id.toString()
      })
      if (idx === -1) {
        return ''
      } else if (this.$i18n.locale === 'pl') {
        return this.publicationTypes[idx].text
      } else {
        return this.publicationTypes[idx].text_en
      }
    },
    setTime (discipline) {
      const momentDate = moment(this.discipline.evaluationDate)
      return momentDate.format('LT')
    },
    numberNPoints (discipline) {
      if (discipline.numberN > 0) {
        return (discipline.points / discipline.numberN).toFixed(4) * (10 / 10)
      } else {
        return 0
      }
    },
    numberNFirstCriteriumPoints (discipline) {
      if (discipline.numberN > 0) {
        return (discipline.firstCriteriumPoints / discipline.numberN).toFixed(4) * (10 / 10)
      } else {
        return 0
      }
    },
    limit220 (discipline) {
      if (discipline.numberN > 0) {
        return (discipline.numberN * 2.2).toFixed(2)
      } else {
        return 0
      }
    },
    disciplinePoints (discipline) {
      if (discipline.points > 0) {
        return discipline.points.toFixed(2) * (100 / 100)
      } else {
        return 0
      }
    },
    addCurrents () {
      this.employees.forEach(e => {
        e.points = this.currentPoints(e)
        e.slots = this.currentSlots(e)
        e.filling = this.currentFillings(e)
      })
    },
    currentPoints (employee) {
      let sum = 0
      employee.publications.forEach(p => {
        if (p.alternativePoints) {
          sum += p.alternativePoints
        } else {
          sum += p.points
        }
      })
      return sum
    },
    currentSlots (employee) {
      let sum = 0
      employee.publications.forEach(p => {
        sum += p.slots
      })
      return sum
    },
    currentFillings (employee) {
      if (employee.maxSlots > 0) {
        return (employee.slots / employee.maxSlots) * 100
      } else {
        return 0
      }
    },
    sortEmployee () {
      const oldEmployees = this.employees
      if (this.sortBy.orderBy === 'points') {
        this.employees = oldEmployees.sort(this.compareBy('points', this.sortBy.orderDirection))
      } else if (this.sortBy.orderBy === 'names') {
        this.employees = oldEmployees.sort(this.compareBy('fullName', this.sortBy.orderDirection))
      } else if (this.sortBy.orderBy === 'fillings') {
        this.employees = oldEmployees.sort(this.compareBy('filling', this.sortBy.orderDirection))
      }
    },
    sortSecondCriterium () {
      const oldProjects = this.discipline.projects
      const oldServices = this.discipline.services
      if (this.secondCriteriumSortBy.orderBy === 'points') {
        this.discipline.projects = oldProjects.sort(this.compareBy('publicationPoints', this.secondCriteriumSortBy.orderDirection))
        this.discipline.services = oldServices.sort(this.compareBy('publicationPoints', this.secondCriteriumSortBy.orderDirection))
      } else if (this.secondCriteriumSortBy.orderBy === 'titles') {
        this.discipline.projects = oldProjects.sort(this.compareBy('title', this.secondCriteriumSortBy.orderDirection))
        this.discipline.services = oldServices.sort(this.compareBy('title', this.secondCriteriumSortBy.orderDirection))
      }
    },
    getEmployees () {
      this.employeesSpinner = true
      ApiDiscipline.getEmployees(this.discipline.id, this.filters)
        .then(result => {
          this.employees = result.data.employees
          this.addCurrents()
          this.sortEmployee()
          this.employeesSpinner = false
        })
        .catch(error => {
          console.log(error)
          this.employeesSpinner = false
        })
    },
    compareBy (field, order) {
      return function (a, b) {
        if (!Object.prototype.hasOwnProperty.call(a, field) || !Object.prototype.hasOwnProperty.call(b, field)) {
          return 0
        }
        let varA
        let varB
        let compare = 0
        if (typeof a[field] === 'string' && typeof b[field] === 'string') {
          varA = a[field].toUpperCase()
          varB = b[field].toUpperCase()
          compare = varA.localeCompare(varB, 'pl-PL')
        } else {
          varA = a[field]
          varB = b[field]
          if (varA > varB) {
            compare = 1
          } else if (varA < varB) {
            compare = -1
          }
        }
        return ((order === 'asc') ? (compare * -1) : compare)
      }
    },
    updateEvaluation () {
      ApiDiscipline.updateEvaluation(this.discipline.id)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.importSpinner = true
            this.waitForJob(jobId, this.evaluationUpdate, this.evaluationError)
          }
        })
    },
    updateNumberN () {
      ApiDiscipline.updateNumberN(this.discipline.id)
        .then(result => {
          const jobId = result.data.jobId
          if (jobId !== undefined) {
            this.numberNSpinner = true
            this.waitForJob(jobId, this.evaluationUpdate, this.evaluationError)
          }
        })
    },
    evaluationUpdate () {
      this.importSpinner = false
      this.numberNSpinner = false
      this.$router.go(0)
    },
    evaluationError () {
      ApiDiscipline.clearJid(this.discipline.id)
        .then((result) => {
          this.importSpinner = false
          this.numberNSpinner = false
          this.unexpectedError()
        })
    },
    changeIgnoreMinuses (discipline) {
      return ApiDiscipline.changeIgnoreMinuses(discipline)
    },
    changeSimulateEmployments () {
      ApiAcademy.simulateEmployment(this.simulateEmployments)
          .then(result => {
            const jobId = result.data.jobId
            if (jobId !== undefined) {
              this.simulateSpinner = true
              this.waitForJob(jobId, this.simulateEmploymentUpdate, this.simulateEmploymentError)
            }
          })
    },
    simulateEmploymentUpdate () {
      this.simulateSpinner = false
      this.$toastr.s(this.$t('views.discipline.show.simulate_employment_success'))
      this.$router.go(0)
    },
    simulateEmploymentError () {
      this.simulateSpinner = false
      this.unexpectedError()
    },
    managersEdit (discipline) {
      this.lastDiscipline = discipline
      this.managers = this.copyManagers(discipline.managers)
      if (this.managers.length === 0) {
        this.managers.push({
          id: null,
          managerName: null
        })
      }
      this.$refs.managers.show()
    },
    copyManagers (managers) {
      const newManagers = []
      managers.forEach(a => {
        const na = {}
        for (const key in a) {
          na[key] = a[key]
        }
        newManagers.push(na)
      })
      return newManagers
    },
    managersSave () {
      for (let i = 0; i < this.managers.length; i++) {
        if (!this.managers[i].userId && !this.managers[i].employeeId) {
          this.managers[i].newId = this.managers[i].id
          this.managers[i].id = null
        }
      }
      if (this.validateManagers(this.managers)) {
        ApiDiscipline.updateManagers(this.discipline.id, this.managers)
          .then(response => {
            this.discipline.managers = this.copyManagers(this.managers)
            this.$refs.managers.hide()
          }).catch(error => {
            this.errors = error.response.data
            this.managers = this.discipline.managers
            this.managersEdit(this.lastDiscipline)
          })
      }
    },
    cleanManagers () {
      this.errors = []
    },
    validateManagers (managers) {
      // TODO
      return true
    },
    publicationFixedPoints (points) {
      if (points && points > 0) {
        return points.toFixed(2)
      } else {
        return 0
      }
    }
  },
  watch: {
    '$i18n.locale': function (newLocale, oldLocale) {
      if (newLocale !== oldLocale) {
        this.buildSortOptions()
        this.$store.dispatch('header/setHeader', {
          title: this.$t(this.headerTitle),
          subtitle: this.headerSubtitle = this.$i18n.locale === 'pl' ? this.discipline.name : this.discipline.nameEn,
          capitalizeSubtitle: false
        })
      }
    },
    contrast (newValue) {
      this.myContrast = newValue
    }
  }
}
</script>

<style scoped lang="scss">
    @import '../../assets/stylesheets/vars';

    .my-exclamation-mark-orange {
      height: 18px;
      width: 18px;
      padding: .2rem .2rem;
      border-radius: 20px;
      text-align: center;
      background-color: $pcg-orange;
      position: relative;
      bottom: .1rem;
    }

    .scientist{
      display: flex;
      align-items: center;
      color: $main-color;
      font-weight: 500;
      padding-bottom: 1rem;
      a {
        color: unset;
      }
    }

    .conversion-jobs{
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    .my-select-short {
      margin-right: .8rem;
      margin-left: .7rem;
      position: relative;
      width: 100px;
      top: .3rem;
      display: inline-block;
      ::v-deep .bootstrap-select .dropdown-toggle {
        height: calc(1.5em + 0.75em + 2px);
        color: $pcg-gray;
      }
      ::v-deep .filter-option-inner {
        position: relative;
        bottom: .45rem;
      }
    }

    .numbers-light {
        background-color: $main-active-color;
        color: $pcg-white;
        font-weight: 500;
        padding: 0.5rem;
        border-radius: 5px;
        margin-right: 0.25rem;
        margin-bottom: .2rem;
        display: inline-block;
    }
    .numbers-dashed {
        background-color: transparent;
        color: $main-active-color;
        padding: 0.5rem;
        border-radius: 5px;
        border: 2px dashed $main-active-color;
        margin-right: 0.25rem;
        font-weight: 500;
    }
    .blue-number {
        color: $main-active-color;
        font-weight: bold;
    }
    .orange-number {
        color: $pcg-orange;
        font-weight: bold;
    }
    .dark-blue-number {
        color: $main-color;
        font-weight: bold;
    }
    .gray-numbers {
        background-color: $pcg-lightest-gray-background;
        color: $pcg-gray;
        margin-left: 0.5rem;
        padding: 5px;
        border-radius: 5px;
    }

    /deep/ .box-container {
        margin-top: $grid-gutter-width;
        margin-bottom: $grid-gutter-width;
        &:first-of-type {
            margin-right: $grid-gutter-width;
        }
        h2 {
            text-transform: uppercase;
            font-weight: bold;
        }
        .gray-background {
            background-color: $pcg-lightest-gray-background;
        }
    }
    .scientists-slots {
        border-bottom: 1px solid $pcg-lightest-gray-background;
        margin-bottom: 1rem;
        &:last-of-type {
            border-bottom: none;
        }
    }
    .grid {
        height: calc(100% - 50px);
        width: 41.66%;
        position: absolute;
        left: 58.33%;
        top: 16px;
        padding: 0 33px 0 5px;
        @media (max-width: 1465.98px) {
            padding: 0 35px 0 5px;
        }
        @media (max-width: 1420.98px) {
            padding: 0 33px 0 5px;
        }
        @media (max-width: 1020.98px) {
            padding: 0 35px 0 5px;
        }
        .slot-1 {
            display: inline-block;
            width: 24.35%;
            height: 100%;
            border-left: 1px solid $pcg-lightest-gray-background;
            border-right: 1px solid $pcg-lightest-gray-background;
            background: transparent;
            @media (max-width: 1420.98px) {
                width: 24.25%;
            }
            @media (max-width: 1285.98px) {
                width: 24.15%;
            }
            @media (max-width: 1190.98px) {
                width: 24%;
            }
            @media (max-width: 1090.98px) {
                width: 23.85%;
            }
            @media (max-width: 1010.98px) {
                width: 23.70%;
            }
            @media (max-width: 950.98px) {
                width: 23.55%;
            }
            @media (max-width: 910.98px) {
                width: 23.40%;
            }
            @media (max-width: 860.98px) {
                width: 23.25%;
            }
            @media (max-width: 825.98px) {
                width: 23.10%;
            }
            @media (max-width: 795.98px) {
                width: 22.90%;
            }
            @media (max-width: 767.98px) {
                width: 23.95%;
            }
            @media (max-width: 715.98px) {
                width: 23.80%;
            }
            @media (max-width: 640.98px) {
                width: 23.65%;
            }
            @media (max-width: 585.98px) {
                width: 23.50%;
            }
            @media (max-width: 540.98px) {
                width: 23.35%;
            }
            @media (max-width: 505.98px) {
                width: 23.20%;
            }
            @media (max-width: 470.98px) {
                width: 23%;
            }
            @media (max-width: 438.98px) {
                width: 22.80%;
            }
            @media (max-width: 410.98px) {
                width: 22.60%;
            }
            @media (max-width: 390.98px) {
                width: 25%;
            }
        }
        .slot-2 {
            display: inline-block;
            width: 24.35%;
            height: 100%;
            border-right: 1px solid $pcg-lightest-gray-background;
            background: transparent;
            @media (max-width: 1420.98px) {
                width: 24.25%;
            }
            @media (max-width: 1285.98px) {
                width: 24.15%;
            }
            @media (max-width: 1190.98px) {
                width: 24%;
            }
            @media (max-width: 1090.98px) {
                width: 23.85%;
            }
            @media (max-width: 1010.98px) {
                width: 23.70%;
            }
            @media (max-width: 950.98px) {
                width: 23.55%;
            }
            @media (max-width: 910.98px) {
                width: 23.40%;
            }
            @media (max-width: 860.98px) {
                width: 23.25%;
            }
            @media (max-width: 825.98px) {
                width: 23.10%;
            }
            @media (max-width: 795.98px) {
                width: 22.90%;
            }
            @media (max-width: 767.98px) {
                width: 23.95%;
            }
            @media (max-width: 715.98px) {
                width: 23.80%;
            }
            @media (max-width: 640.98px) {
                width: 23.65%;
            }
            @media (max-width: 585.98px) {
                width: 23.50%;
            }
            @media (max-width: 540.98px) {
                width: 23.35%;
            }
            @media (max-width: 505.98px) {
                width: 23.20%;
            }
            @media (max-width: 470.98px) {
                width: 23%;
            }
            @media (max-width: 438.98px) {
                width: 22.80%;
            }
            @media (max-width: 410.98px) {
                width: 22.60%;
            }
            @media (max-width: 390.98px) {
                width: 21.55%;
            }
        }
        .slot-3 {
            display: inline-block;
            width: 24.35%;
            height: 100%;
            border-right: 1px solid $pcg-lightest-gray-background;
            background: transparent;
            @media (max-width: 1420.98px) {
                width: 24.25%;
            }
            @media (max-width: 1285.98px) {
                width: 24.15%;
            }
            @media (max-width: 1190.98px) {
                width: 24%;
            }
            @media (max-width: 1090.98px) {
                width: 23.85%;
            }
            @media (max-width: 1010.98px) {
                width: 23.70%;
            }
            @media (max-width: 950.98px) {
                width: 23.55%;
            }
            @media (max-width: 910.98px) {
                width: 23.40%;
            }
            @media (max-width: 860.98px) {
                width: 23.25%;
            }
            @media (max-width: 825.98px) {
                width: 23.10%;
            }
            @media (max-width: 795.98px) {
                width: 22.90%;
            }
            @media (max-width: 767.98px) {
                width: 23.95%;
            }
            @media (max-width: 715.98px) {
                width: 23.80%;
            }
            @media (max-width: 640.98px) {
                width: 23.65%;
            }
            @media (max-width: 585.98px) {
                width: 23.50%;
            }
            @media (max-width: 540.98px) {
                width: 23.35%;
            }
            @media (max-width: 505.98px) {
                width: 23.20%;
            }
            @media (max-width: 470.98px) {
                width: 23%;
            }
            @media (max-width: 438.98px) {
                width: 22.80%;
            }
            @media (max-width: 410.98px) {
                width: 22.60%;
            }
            @media (max-width: 390.98px) {
                width: 21.55%;
            }
        }
        .slot-4 {
            display: inline-block;
            width: 24.35%;
            height: 100%;
            border-right: 1px solid $pcg-lightest-gray-background;
            background: transparent;
            @media (max-width: 1420.98px) {
                width: 24.25%;
            }
            @media (max-width: 1285.98px) {
                width: 24.15%;
            }
            @media (max-width: 1190.98px) {
                width: 24%;
            }
            @media (max-width: 1090.98px) {
                width: 23.85%;
            }
            @media (max-width: 1010.98px) {
                width: 23.70%;
            }
            @media (max-width: 950.98px) {
                width: 23.55%;
            }
            @media (max-width: 910.98px) {
                width: 23.40%;
            }
            @media (max-width: 860.98px) {
                width: 23.25%;
            }
            @media (max-width: 825.98px) {
                width: 23.10%;
            }
            @media (max-width: 795.98px) {
                width: 22.90%;
            }
            @media (max-width: 767.98px) {
                width: 23.95%;
            }
            @media (max-width: 715.98px) {
                width: 23.80%;
            }
            @media (max-width: 640.98px) {
                width: 23.65%;
            }
            @media (max-width: 585.98px) {
                width: 23.50%;
            }
            @media (max-width: 540.98px) {
                width: 23.35%;
            }
            @media (max-width: 505.98px) {
                width: 23.20%;
            }
            @media (max-width: 470.98px) {
                width: 23%;
            }
            @media (max-width: 438.98px) {
                width: 22.80%;
            }
            @media (max-width: 410.98px) {
                width: 22.60%;
            }
            @media (max-width: 390.98px) {
                width: 21.55%;
            }
        }
    }
    .my-score-icon {
        width: 18px;
        height: 18px;
        margin-right: 1rem;
        margin-bottom: .8rem;
        position: relative;
        top: .3rem;
    }
    .slots-chart {
        width: 18px;
        height: 18px;
        display: inline-block;
        margin-right: 1rem;
        position: relative;
        top: .4rem;
    }

    .field-name {
        display: inline-block;
        font-size: $font-size-m;
        text-transform: uppercase;
        font-weight: 400;
    }

    .manager-name {
        display: inline-block;
        font-size: $font-size-m;
        font-weight: 400;
    }

    .one-manager {
        color: $main-color;
        margin-right: 1rem;
        font-weight: 600;
        text-transform: uppercase;
        &:last-of-type {
            margin-right: 0;
        }
        a {
            color: unset;
        }
    }

    .discipline-points {
        color: $main-color;
        font-weight: 400;
    }

    .oneline {
        margin-bottom: 1rem;
    }

    .actual-score {
        background: $pcg-lightest-gray-background;
        padding: 15px 20px;
        border-radius: 10px;
        margin: 2rem 1px;
    }

    .persons {
        color: $pcg-gray;
        font-weight: 400;
    }

    .sort {
        color: $pcg-gray;
        font-weight: 400;
        margin-left: 2rem;
        margin-right: .7rem;
        position: relative;
        top: .5rem;
    }

    .my-sort {
      position: relative;
      top: .8rem;
      display: inline-flex !important;
    }

    .evaluation {
        color: $pcg-gray;
        font-weight: 400;
    }

    .no-records {
        color: $pcg-dark-gray;
        font-weight: 400;
        font-size: $font-size-m;
    }

    /deep/ .tooltip-inner {
      background: $pcg-white;
      color: $pcg-dark-gray;
      box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
        font-weight: 400;
        padding: .5rem;
        border-color: $pcg-light-gray-background;
        border-style: solid;
        border-width: 1px;
        opacity: 1;
    }

    /deep/ .arrow::before {
        border-top-color: $pcg-light-gray-background !important;
    }

    .change {
        color: $main-active-color;
        font-size: $font-size-s;
        font-weight: 400;
        cursor: pointer;
        text-transform: lowercase;
    }

    .my-box {
        min-width: 300px !important;
    }

    .my-loader {
        margin-top: 4rem;
        height: 300px;
    }

    .my-n-icon {
        color: $main-color;
        font-size: $font-size-m;
        font-weight: 400;
        position: relative;
        left: .3rem;
        margin-right: 1.4rem;
    }

    .no-manager {
        font-size: $font-size-s;
        font-weight: 400;
        color: $dn-orange;
        margin-right: 1rem;
    }

    .disabled {
        pointer-events: none;
    }

    .discipline-name {
        margin-top: .2rem;
        color: $main-active-color;
        font-size: 1.1em;
        font-weight: 400;
        text-transform: uppercase;
    }

    .achievement-elements {
        margin-top: 1rem;
        border-radius: 15px;
        background-color: $pcg-white;
        padding: 1rem;

        .achievement {
            border-bottom: 1px solid $pcg-lightest-gray-background;
            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .points {
        font-weight: 500;
        min-width: 30px;
        width: fit-content;
        margin-right: 0 !important;
        font-size: $font-size-s;
    }

    .scientist-points {
        margin-bottom: .5rem;
        @media (max-width: 830.98px) {
            position: relative;
            right: 1.5rem;
        }
        @media (max-width: 410.98px) {
            position: relative;
            right: 2rem;
        }
    }

    .project-type {
        color: $pcg-gray;
        font-weight: 400;
        margin: auto;
        padding-bottom: .5rem;
    }

    .project-title {
        color: $pcg-gray;
        font-weight: 400;
        margin: auto;
        padding-bottom: .5rem;
    }

    .icon-not-enough {
        width: 16px;
        height: 16px;
        padding: 1px;
        border-radius: 30px;
        border: 2px solid;
        border-color: $pcg-red;
        margin-right: .2rem;
        position: relative;
        bottom: .15rem;
    }

    .simulate-spinner {
      color: $main-active-color;
    }
</style>

<style lang="scss" scoped>
    @import "../../assets/stylesheets/vars-contrast";
    .contrast {
        .numbers-light {
            background-color: $main-active-color;
            color: $pcg-white;
        }
        .numbers-dashed {
            background-color: transparent;
            color: $main-active-color;
            border: 2px dashed $main-active-color;
        }
        .blue-number {
            color: $main-active-color;
        }
        .orange-number {
            color: $pcg-orange;
        }
        .dark-blue-number {
            color: $main-color;
        }
        .gray-numbers {
            background-color: $pcg-lightest-gray;
            color: $pcg-gray;
        }
        /deep/ .box-container {
            .gray-background {
                background-color: $pcg-lightest-gray;
            }
        }
        .scientists-slots {
            border-bottom: 1px solid $pcg-lightest-gray;
        }
        .grid {
            .slot-1 {
                border-left: 1px solid $pcg-lightest-gray;;
                border-right: 1px solid $pcg-lightest-gray;
                background: transparent;
            }
            .slot-2 {
                border-right: 1px solid $pcg-lightest-gray;
                background: transparent;
            }
            .slot-3 {
                border-right: 1px solid $pcg-lightest-gray;
                background: transparent;
            }
            .slot-4 {
                border-right: 1px solid $pcg-lightest-gray;
                background: transparent;
            }
        }
        .one-manager {
            color: $main-color;
        }

        .discipline-points {
            color: $main-color;
        }
        .actual-score {
            background: $pcg-lightest-gray;
        }
        .persons {
            color: $pcg-gray;
        }
        .sort {
            color: $pcg-gray;
        }
        .evaluation {
            color: $pcg-gray;
        }
        .no-records {
            color: $pcg-dark-gray;
        }
        /deep/ .tooltip-inner {
          background: $pcg-white;
          color: $pcg-dark-gray;
          box-shadow: 0 3px 10px rgba(0, 0, 0, .2);
            border-color: $pcg-light-gray;
        }
        /deep/ .arrow::before {
            border-top-color: $pcg-light-gray !important;
        }
        .change {
            color: $main-active-color;
        }
        .my-sort {
            color: $pcg-gray;
            /deep/ .bootstrap-select .dropdown-toggle {
                color: $main-color !important;
                border: none;
            }
        }
        .my-n-icon {
            color: $main-color;
        }
        .achievement-elements {
            background-color: $pcg-white;

            .achievement {
                border-bottom: 1px solid $pcg-lightest-gray;
            }
        }
        .project-type {
            color: $pcg-gray;
        }

        .project-title {
            color: $pcg-gray;
        }
        .discipline-name {
            color: $main-active-color;
        }
        .pcg-main-btn {
            &:disabled {
                background-color: $disabled-color !important;
            }
        }
      .simulate-spinner {
        color: $main-active-color;
      }
    }
</style>
